<template>
  <div>
    <confirm-modal
      v-if="showModal"
      :title="modalTitle"
      :message="modalMessage"
      @confirmed="onConfirmed"
      @declined="onDeclined"
    />
    <div class="container">
      <observation-form-area
        :currentObservation="currentObservation"
        :collectionForm="collectionForm"
      >
      </observation-form-area>
    </div>

    <div class="bottom-nav">
      <button
        @click="stopTimer"
        class="btn btn-outline-primary bottom-action"
        type="button"
      >
        <h1 class="counter-time">
          <i
            class="material-icons md-38"
            v-bind:class="{ 'text-danger': timeout }"
          >
            timer
          </i>
          <span>{{ timerCountWithMinutes }}</span>
        </h1>
      </button>
      <button
        v-if="timeout"
        type="button"
        class="btn bottom-action btn-default"
        v-on:click="observationsCompleted"
      >
        <i class="material-icons button-material-icon"> done </i>
        {{ $englishPreferred ? "Done" : "Afslut" }}
      </button>
    </div>
  </div>
</template>

<script>
import ObservationFormArea from "./observation/ObservationFormArea.vue";
import ConfirmModal from "./ConfirmModal.vue";

export default {
  name: "AddObservationsToCount",
  props: [
    "currentObservation",
    "collectionForm",
    "timerStarted",
    "alarmSound",
    "noSleep",
  ],
  components: {
    ObservationFormArea,
    ConfirmModal,
  },
  methods: {
    showConfirmModal() {
      this.modalTitle = "Bekræft 0 observationer";
      this.modalMessage =
        "Du har ikke tilføjet nogen observationer - er det korrekt at der ingen observationer var?";
      this.showModal = true;
    },
    onConfirmed() {
      // Handle the OK button click here
      // This function is called when the user clicks "OK" in the modal
      this.showModal = false;

      this.$emit("submit-completed-count");
    },
    onDeclined() {
      this.showModal = false;
    },
    observationsCompleted() {
      this.alarmSound.pause();
      // Check if there's any observations, and if not, show pop-up window prompting user to confirm no observations
      if (this.collectionForm.observations.length < 1) {
        this.showConfirmModal();
      } else {
        this.$emit("submit-completed-count");
      }
    },
    stopTimer() {
      if (this.timeout === true) {
        this.timeStopped = true;
        this.alarmSound.pause();
      }
    },
  },
  data() {
    return {
      timeout: false,
      countdownSeconds: 300,
      startTime: null,
      secondsPassed: null,
      timeStopped: false,
      showModal: false,
      modalTitle: "",
      modalMessage: "",
    };
  },
  watch: {
    secondsPassed: {
      handler(value) {
        setTimeout(() => {
          if (this.timeStopped === false) {
            this.secondsPassed = Date.now() / 1000 - this.startTime / 1000;
            if (
              this.timeout == false &&
              this.secondsPassed > this.countdownSeconds
            ) {
              this.timeout = true;
              this.noSleep.disable();
              this.alarmSound.play();
              navigator.vibrate(5000);
            }
          }
        }, 100);
      },
    },
    timerStarted() {
      this.startTime = Date.now();
      this.secondsPassed = 0;
    },
  },
  computed: {
    timerCountWithMinutes() {
      let totalSecondsLeft = this.timeout
        ? this.secondsPassed - this.countdownSeconds
        : this.countdownSeconds - this.secondsPassed;

      let minutesLeft = Math.floor(totalSecondsLeft / 60);
      let secondsLeft = Math.floor(totalSecondsLeft % 60);

      let string = "";
      if (this.timeout == true) {
        string += "-";
      }
      if (minutesLeft < 10) {
        string += "0";
      }
      string += minutesLeft;
      string += ":";
      if (secondsLeft < 10) {
        string += "0";
      }
      string += secondsLeft;
      return string;
    },
  },
};
</script>

<style scoped>
.md-38 {
  position: relative;
  /* Adjust these values accordingly */
  top: 9px;
  font-size: 38px !important;
}

.btn-outline-primary {
  background-color: #ffff;
}

.counter-time {
  color: #1f7aaf;
}
</style>
