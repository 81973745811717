<template>
  <nav-header
    @navigate-backwards="navigateBackwards"
    :isInstructionsPage="isInstructionsPage"
    :isCountInProcess="isCountInProcess"
    :countIsCompleted="showCountCompletion"
    :title="navTitle"
  ></nav-header>
  <div>
    <count-instructions
      v-if="showCountInstructions"
      @switch-to-map="switchToMap"
    ></count-instructions>
    <MapCompletedCount
      key="on"
      v-if="showMapCompletedCount"
      @switch-to-date-and-time="switchToObservations"
      :collectionForm="collectionForm"
    />
    <AddObservationsToCount
      v-show="showObservations"
      @submit-completed-count="submitCompletedCount"
      :collectionForm="collectionForm"
      :timerStarted="timerStarted"
      :alarmSound="alarmSound"
      :noSleep="noSleep"
      :currentObservation="currentObservation"
      :observations="collectionForm.observations"
    />

    <CountConfirmation
      v-if="showCountConfirmation"
      @confirm-completed-count="confirmCompletedCount"
      :collectionForm="collectionForm"
      @change-position-after-count="changePositionAfterCount"
    />
    <CountCompletion
      :collectionForm="collectionForm"
      :countPoints="countPoints"
      v-if="showCountCompletion"
    />
  </div>
</template>

<script>
const offlineAudio = require("../assets/audio/level-completed.mp3");
const alarmSoundUrl = null;
const alarmSound = null;
import MapCompletedCount from "../components/MapCompletedCount.vue";
import AddObservationsToCount from "../components/AddObservationsToNewCount.vue";
import CountConfirmation from "../components/CountConfirmation.vue";
import CountCompletion from "../components/CountCompletion.vue";
import NavHeader from "@/components/navbars/NavHeader.vue";
import observationRepository from "../idb/repositories/observationRepository";
import recentBehavioursRepository from "../idb/repositories/recentBehavioursRepository";
import recentSpeciesRepository from "../idb/repositories/recentSpeciesRepository";
import collectionRepository from "../idb/repositories/collectionRepository";
import CountInstructions from "../components/CountInstructions.vue";
import countPointsRepository from "../idb/repositories/countPointsRepository";
import NoSleep from "nosleep.js";
import axios from "axios";
import atlasSquarePointRepository from "../idb/repositories/atlasSquarePointRepository";
import localforage from "localforage";

export default {
  name: "AddCompletedCount",
  components: {
    MapCompletedCount,
    AddObservationsToCount,
    CountConfirmation,
    CountCompletion,
    NavHeader,
    CountInstructions,
    localforage,
  },
  mounted() {
    localforage.getItem("audio").then((audio) => {
      if (audio) {
        this.alarmSoundUrl = URL.createObjectURL(audio);
      } else {
        if (this.attemptedInsertingAudio == false) {
          //offlineMap not found - attempt insert and then use
          this.attemptedInsertingAudio = true;
          this.prepareAudio();
        }
      }
    });
  },
  methods: {
    async prepareAudio() {
      fetch(this.offlineAudio)
        .then((res) => res.blob())
        .then((blob) => {
          localforage.setItem("audio", blob);
          this.alarmSoundUrl = URL.createObjectURL(blob);
        });
    },
    navigateBackwards() {
      if (this.showMapCompletedCount) {
        this.showMapCompletedCount = false;
        this.isInstructionsPage = true;
        this.showCountInstructions = true;
      } else if (this.showObservations) {
        this.showObservations = false;
        this.showMapCompletedCount = true;
      } else if (this.showCountConfirmation) {
        this.showCountConfirmation = false;
        this.showObservations = true;
      }
    },
    switchToMap() {
      this.isInstructionsPage = false;
      this.isCountInProcess = true;
      this.showCountInstructions = false;
      this.showMapCompletedCount = true;
    },
    switchToObservations() {
      this.showMapCompletedCount = false;

      const moment = require("moment-timezone");

      // create a new moment object with the current date and time in the "Europe/Copenhagen" timezone
      const momentDate = moment.tz("Europe/Copenhagen");

      // format the date in "yyyy-mm-dd" format
      const formattedDate = momentDate.format("YYYY-MM-DD");

      this.collectionForm.date = formattedDate;

      // Set start time
      const d = new Date();
      let h = this.addZero(d.getHours());
      let m = this.addZero(d.getMinutes());
      let time = h + ":" + m;
      this.collectionForm.startTime = time;
      // Set end time
      const dEnd = this.addMinutes(d, 5);
      this.pointDatetime = dEnd;
      h = this.addZero(dEnd.getHours());
      m = this.addZero(dEnd.getMinutes());
      time = h + ":" + m;
      this.collectionForm.endTime = time;

      this.timerStarted = true;

      this.alarmSound = new Audio(this.alarmSoundUrl);
      this.alarmSound.src = this.alarmSoundUrl;

      this.noSleep = new NoSleep();
      this.noSleep.enable();
      this.showObservations = true;
    },
    submitCompletedCount() {
      this.showObservations = false;
      this.showCountConfirmation = true;
    },
    changePositionAfterCount() {
      // First navigate back to observations, then to map
      this.navigateBackwards();
      this.navigateBackwards();
    },
    async confirmCompletedCount() {
      this.isCountInProcess = false;
      this.showCountConfirmation = false;
      this.showCountCompletion = true;
      // Copy observations in collectionform, preparing them to be inserted in indexeddb separately
      let observations = JSON.parse(
        JSON.stringify(this.collectionForm.observations)
      );
      // Now remove observations from collectionform
      delete this.collectionForm.observations;
      // Insert collectionForm in IndexedDB and retrieve ID, then insert observations, then insert recent species and behaviours
      let unwrappedCollection = {
        ...JSON.parse(JSON.stringify(this.collectionForm)),
      };
      await collectionRepository
        .insertCollection(unwrappedCollection)
        .then(async (localCollectionId) => {
          unwrappedCollection.id = localCollectionId;
          await this.addObservationsToDatabase(observations, localCollectionId);
          await this.addCountPointsToDatabase(localCollectionId);
          axios
            .post("/spontan/collections/", unwrappedCollection)
            .then((response) => {
              let collectionApiId = response.data;
              console.log(
                "adding observations to DB, collectionApiID: " + collectionApiId
              );
              unwrappedCollection.apiId = collectionApiId;

              collectionRepository.insertCollection(unwrappedCollection);
              observations.forEach((observation) => {
                observation.collectionApiId = collectionApiId;
                // For each observation send it to API, and then set the apiId for it, and update it in local database
                axios
                  .post("/spontan/observations/", observation)
                  .then((response) => {
                    let observationApiId = response.data;
                    observation.apiId = observationApiId;
                    observation.collectionId = localCollectionId;
                    observation.unsynched = false;
                    observationRepository.insertObservation(observation);
                  });
              });
            });
        });

      observations.forEach((observation) => {
        //recentBehaviour
        recentBehavioursRepository.insertRecentBehaviour(
          observation.chosenBehaviours
        );
        //recentSpecie
        recentSpeciesRepository.insertRecentSpecie(observation.specie);
      });
    },
    addObservationsToDatabase(observations, collectionId) {
      observations.forEach((observation) => {
        observation.collectionId = collectionId;
        observationRepository
          .insertObservation(observation)
          .then((localObservationId) => {
            observation.id = localObservationId;
          });
      });
    },
    async addCountPointsToDatabase(collectionId) {
      /** COUNT POINTS
       * 1. Figure out how many points this count is awarded
       * 2. Insert countPoints in IndexedDB
       */
      // 1. get the countPoints on same atlas square number
      let countPointsOnSameAtlasSquare =
        await countPointsRepository.getCountPointsByAtlasSquareNumber(
          this.collectionForm.atlasSquareNumber
        );
      // try to fetch points from atlasSquarePoints if any exists for this square, otherwise give 100 by default
      let atlasSquarePoint =
        await atlasSquarePointRepository.getAtlasSquarePointByAtlasSquareNumber(
          this.collectionForm.atlasSquareNumber
        );
      let points = 100;
      if (atlasSquarePoint != null && atlasSquarePoint.length !== 0) {
        points = atlasSquarePoint[0].points;
      }

      const hour = 1000 * 60 * 60;
      let givePoints = false;
      let recentCount = null;
      if (
        countPointsOnSameAtlasSquare != null &&
        countPointsOnSameAtlasSquare.length !== 0
      ) {
        for (let i = 0; i < countPointsOnSameAtlasSquare.length; i++) {
          const pointDate = new Date(this.pointDatetime);
          const countPointDate = new Date(
            countPointsOnSameAtlasSquare[i].datetime
          );

          // Set the time of day to midnight (00:00:00)
          pointDate.setHours(0, 0, 0, 0);
          countPointDate.setHours(0, 0, 0, 0);

          if (pointDate.getTime() === countPointDate.getTime()) {
            console.log("same date, 10 points");
            points = 10;
          }

          if (
            this.pointDatetime.getTime() <
            countPointsOnSameAtlasSquare[i].datetime.getTime() + hour
          ) {
            if (
              recentCount != null &&
              recentCount.datetime.getTime() <
                countPointsOnSameAtlasSquare[i].datetime.getTime()
            ) {
              recentCount = countPointsOnSameAtlasSquare[i];
            } else if (recentCount == null) {
              recentCount = countPointsOnSameAtlasSquare[i];
            }
          }
        }
      }
      if (recentCount != null) {
        // Check recent count gave more than 0 points, and another count within 1 hour of the recent count found,
        // but is also more than one hour ago from the current count - if no such count is found, set points to 0
        for (let i = 0; i < countPointsOnSameAtlasSquare.length; i++) {
          if (
            this.pointDatetime.getTime() >
              countPointsOnSameAtlasSquare[i].datetime.getTime() + hour &&
            recentCount.datetime.getTime() <
              countPointsOnSameAtlasSquare[i].datetime.getTime() + hour &&
            recentCount.points == 0
          ) {
            givePoints = true;
          }
        }
        if (givePoints === false) {
          points = 0;
        }
      }
      let countPoints = {
        kvadratnr: null,
        collectionId: null,
        collectionIdApi: null,
        datetime: null,
        points: points,
      };

      // Set kvadratnr
      countPoints.kvadratnr = this.collectionForm.atlasSquareNumber;
      // Set collectionId
      countPoints.collectionId = collectionId;
      // Set collectionIdApi
      if (this.collectionForm.apiId) {
        countPoints.collectionIdApi = this.collectionForm.apiId;
      }
      // Set datetime
      countPoints.datetime = this.pointDatetime;

      // Set this.countPoints, so we can show points in next view
      this.countPoints = countPoints;

      // 2. insert countPoints into indexedDB
      countPointsRepository.insertCountPoints(countPoints);
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    addMinutes(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    },
  },
  data() {
    return {
      alarmSoundUrl,
      alarmSound,
      offlineAudio,
      noSleep: null,
      navTitle: this.$englishPreferred ? "New count" : "Ny tælling",
      isCountInProcess: false,
      countPoints: null,
      pointDatetime: null,
      timerStarted: false,
      isInstructionsPage: true,
      attemptedInsertingAudio: false,

      currentObservation: {
        apiId: null,
        collectionId: null,
        collectionApiId: null,
        count: 1,
        specie: null,
        age: 1,
        sex: "-",
        plumage: 1,
        secret: false,
        notes: null,
        calling: false,
        heard: false,
        migratingInwards: false,
        migratingOutwards: false,
        oilDamaged: false,
        startTime: null,
        endTime: null,
        coordinate: {
          latitude: null,
          longitude: null,
          radius: 50,
        },
        chosenBehaviours: {
          primary: null,
          secondary: null,
          direction: null,
        },
      },
      collectionForm: {
        apiId: null,
        date: null,
        startTime: null,
        endTime: null,
        site: null,
        coObserver: null,
        windDirection: null,
        windForce: null,
        cloudCover: null,
        temperature: null,
        downpour: null,
        downpourType: null,
        visibility: null,
        notes: null,
        observations: [],
        latitude: null,
        longitude: null,
        atlasSquareNumber: null,
      },
      showCountInstructions: true,
      showMapCompletedCount: false,
      showPickDateAndTime: false,
      showObservations: false,
      showCountConfirmation: false,
      showCountCompletion: false,
    };
  },
};
</script>
