<template>
  <div>
    <div class="container">
      <h5 class="mb-4">
        {{
          $englishPreferred
            ? "Good info before you start your count"
            : "Nyttig info før du begynder din tælling"
        }}
      </h5>
      <ol>
        <li>
          <b>
            {{
              $englishPreferred
                ? "Notice! remember to enable sound on your phone, so alarm sound can be heard when count timer is finished."
                : "OBS! Husk at tænde lyden på din telefon, så alarmen ved endt tælling høres."
            }}
          </b>
        </li>
        <li>
          {{
            $englishPreferred
              ? "Count position should have good visibility."
              : "Tællepunktet placeres med godt udsyn."
          }}
        </li>
        <li>
          {{
            $englishPreferred
              ? "All bird species must be registered with amount and primary behaviour."
              : "Alle fuglearter skal registreres med antal og som minimum primær adfærdskode."
          }}
        </li>
        <li>
          {{
            $englishPreferred
              ? "All visible birds or birds which can be heard from your count position, within 1 kilometer radius, is counted."
              : "Alle de fugle, der ses eller høres fra optællingspunktet, og som kan artsbestemmes, inden for ca. 1 km fra tællepunktet tælles med."
          }}
        </li>
        <li>
          {{
            $englishPreferred
              ? "If you're in doubt, please read the guide again."
              : "Er du i tvivl om noget, så læs hellere vejledningen en gang mere."
          }}
          <br />
          <router-link to="/vejledning"
            ><button class="btn mt-2 btn-primary">
              <i class="material-icons button-material-icon"> help </i>
              {{ $englishPreferred ? "Guide" : "Vejledning" }}
            </button></router-link
          >
        </li>
      </ol>
      <p>{{ $englishPreferred ? "Have fun!" : "God obs!" }}</p>
    </div>
    <div style="background-color: red" class="bottom-nav">
      <button
        type="button"
        class="btn btn-default bottom-action"
        v-on:click="$emit('switch-to-map')"
      >
        <i class="material-icons button-material-icon"> done </i>
        {{ $englishPreferred ? "Understood" : "Forstået" }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "countInstructions",
};
</script>

<style scoped></style>
